import Events from "../pages/Events";
import Login from "../pages/Login";
import Projects from "../pages/Projects";
import Users from "../pages/Users";
import Profile from "../pages/Profile";
import Settings from "../pages/Settings";
import Event from "../pages/Event";
import Project from "../pages/Project";
import User from "../pages/User";
import CreateEventProject from "../pages/CreateEventProject";
import EditEvent from "../pages/EditEvent";
import CreateEditPackage from "../pages/CreateEditPackage";
import EditProject from "../pages/EditProject";
import Bookmarks from "../pages/Bookmarks";
import Messages from "../pages/Messages";
import Chat from "../pages/Chat";
import ProfileEdit from "../pages/ProfileEdit";
import Tickets from "../pages/Tickets";
import Registration from "../pages/Registration";
import BuyPackage from "../pages/BuyPackage";
import Thanks from "../pages/Thanks";
import MyTickets from "../pages/MyTickets";
import CheckTicket from "../pages/CheckTicket";
import ForgotPassword from "../pages/ForgotPassword";
import PasswordRecovery from "../pages/PasswordRecovery";
import Followers from "../pages/Followers";
import Subscriptions from "../pages/Subscriptions";
import SettingsPrivacy from "../pages/SettingsPrivacy";
import SettingsSecurity from "../pages/SettingsSecurity";
import SettingsNotifications from "../pages/SettingsNotifications";
import EventsFlow from "../pages/EventsFlow";
import Help from "../pages/Help";
import HowToCheckTickets from "../pages/help/HowToCheckTickets";
import Referral from "../pages/Referral";
import { Navigate } from "react-router-dom";
import SettingsVerification from "../pages/SettingsVerification";
import VerificationVkid from "../pages/VerificationVkid";
import MeetingParticipants from "../pages/MeetingParticipants";
import AuthVkid from "../pages/AuthVkid";
import Post from "../pages/Post";
import Feed from "../pages/Feed";
import ObjectReviews from "../pages/ObjectReviews";

export const privateRoutes = [
  { path: "/", element: <Events /> },
  { path: "/events", element: <Events /> },
  { path: "/events/flow", element: <EventsFlow /> },
  { path: "/events/create", element: <CreateEventProject mode={"event"} /> },
  { path: "/events/edit/:id", element: <EditEvent /> },
  { path: "/events/:id", element: <Event /> },
  { path: "/events/:id/reviews", element: <ObjectReviews /> },
  { path: "/projects", element: <Projects /> },
  {
    path: "/projects/create",
    element: <CreateEventProject mode={"project"} />,
  },
  { path: "/projects/edit/:id", element: <EditProject /> },
  { path: "/projects/:id", element: <Project /> },
  { path: "/users", element: <Users /> },
  { path: "/users/:id", element: <User /> },
  { path: "/profile", element: <Profile /> },
  { path: "/profile/edit", element: <ProfileEdit /> },
  { path: "/packages/create", element: <CreateEditPackage /> },
  { path: "/packages/edit", element: <CreateEditPackage mode={"edit"} /> },
  { path: "/packages/:id/users", element: <MeetingParticipants /> },
  { path: "/packages/:id/chat", element: <Chat isPackage={true} /> },
  { path: "/bookmarks", element: <Bookmarks /> },
  { path: "/messages", element: <Messages /> },
  { path: "/messages/:id", element: <Chat /> },
  { path: "/tickets", element: <MyTickets /> },
  { path: "/tickets/check/:id", element: <CheckTicket /> },
  { path: "/tickets/buy/:id", element: <BuyPackage /> },
  { path: "/tickets/:id", element: <Tickets /> },
  { path: "/thanks", element: <Thanks /> },
  { path: "/followers", element: <Followers /> },
  { path: "/subscriptions", element: <Subscriptions /> },
  { path: "/settings", element: <Settings /> },
  { path: "/settings/privacy", element: <SettingsPrivacy /> },
  { path: "/settings/security", element: <SettingsSecurity /> },
  { path: "/settings/notifications", element: <SettingsNotifications /> },
  { path: "/settings/verification", element: <SettingsVerification /> },
  { path: "/settings/verification/vkid", element: <VerificationVkid /> },
  { path: "/referral", element: <Referral /> },
  { path: "/posts/:id", element: <Post /> },
  { path: "/feed", element: <Feed /> },
  // { path: '/forgot', element: <ForgotPassword /> },
  // { path: '/recovery/:email/:key', element: <PasswordRecovery /> },
  { path: "/help", element: <Help /> },
  // { path: "/help/how-to-check-tickets", element: <HowToCheckTickets /> },
];

export const publicRoutes = [
  { path: "/", element: <Events /> },
  { path: "/login", element: <Login /> },
  { path: "/login/vkid", element: <AuthVkid /> },
  { path: "/signup", element: <Registration /> },
  { path: "/events", element: <Events /> },
  { path: "/events/flow", element: <EventsFlow /> },
  { path: "/events/create", element: <Navigate to="/login" replace /> },
  { path: "/events/:id", element: <Event /> },
  { path: "/events/:id/reviews", element: <ObjectReviews /> },
  { path: "/projects", element: <Projects /> },
  {
    path: "/projects/create",
    element: <Navigate to="/login" replace />,
  },
  { path: "/projects/:id", element: <Project /> },
  { path: "/users/:id", element: <User /> },
  { path: "/tickets/check/:id", element: <CheckTicket /> },
  { path: "/tickets/buy/:id", element: <BuyPackage /> },
  { path: "/thanks", element: <Thanks /> },
  { path: "/forgot", element: <ForgotPassword /> },
  { path: "/recovery/:email/:key", element: <PasswordRecovery /> },
  { path: "/help", element: <Help /> },
  { path: "/posts/:id", element: <Post /> },
  // { path: "/help/how-to-check-tickets", element: <HowToCheckTickets /> },
];

// export const privateRoutes = [
//     { path: '/events', element: <Events /> },
//     { path: '/events/create', element: <CreateEventProject mode={"event"} /> },
//     { path: '/events/edit/:id', element: <EditEvent /> },
//     { path: '/events/:id', element: <Event /> },
//     { path: '/projects', element: <Projects /> },
//     { path: '/projects/create', element: <CreateEventProject mode={"project"} /> },
//     { path: '/projects/edit/:id', element: <EditProject /> },
//     { path: '/projects/:id', element: <Project /> },
//     { path: '/users', element: <Users /> },
//     { path: '/users/:id', element: <User /> },
//     { path: '/profile', element: <Profile /> },
//     { path: '/profile/edit', element: <ProfileEdit /> },
//     { path: '/packages/create', element: <CreateEditPackage /> },
//     { path: '/packages/edit', element: <CreateEditPackage mode={'edit'} /> },
//     { path: '/bookmarks', element: <Bookmarks /> },
//     { path: '/messages', element: <Messages /> },
//     { path: '/messages/:id', element: <Chat /> },
//     { path: '/tickets/:id', element: <Tickets /> },
// ]
