import React from "react";
import UserSmallCard from "./UserSmallCard";

const UserSmallCardList = ({
  usersData = null,
  users = null,
  isPastel = false,
  onlyCommonCol = false,
  actionBtnData1 = null,
  actionBtnData2 = null,
}) => {
  let emptyContent = (
    <div className="row center-align">
      <div className="col s12">
        Пользователи не найдены или
        <br />
        ваш профиль не заполнен
      </div>
    </div>
  );
  if (users) {
    usersData = {
      elements: users,
    };
  }

  if (!usersData) return emptyContent;
  if (!usersData.hasOwnProperty("elements")) return emptyContent;
  if (usersData.elements.length == 0) {
    return emptyContent;
  }

  let col1 = [];
  let col2 = [];
  let col3 = [];
  let allUserCards = [];

  let curCol = 1;
  usersData.elements.forEach((element) => {
    allUserCards.push(
      <UserSmallCard
        user={element}
        key={Math.random() * 1000000}
        isPastel={isPastel}
        actionBtnData1={actionBtnData1}
        actionBtnData2={actionBtnData2}
      />
    );
    switch (curCol) {
      case 1:
        col1.push(
          <UserSmallCard
            user={element}
            key={Math.random() * 1000000}
            isPastel={isPastel}
            actionBtnData1={actionBtnData1}
            actionBtnData2={actionBtnData2}
          />
        );
        curCol = 2;
        break;

      case 2:
        col2.push(
          <UserSmallCard
            user={element}
            key={Math.random() * 1000000}
            isPastel={isPastel}
            actionBtnData1={actionBtnData1}
            actionBtnData2={actionBtnData2}
          />
        );
        curCol = 3;
        break;

      case 3:
        col3.push(
          <UserSmallCard
            user={element}
            key={Math.random() * 1000000}
            isPastel={isPastel}
            actionBtnData1={actionBtnData1}
            actionBtnData2={actionBtnData2}
          />
        );
        curCol = 1;
        break;

      default:
        break;
    }
  });
  // usersData.elements.map((el, index, arr) => {
  //   return <UserSmallCard user={el} key={el.alias} />;
  // });

  return (
    <div className="row nm">
      <div
        className={
          onlyCommonCol ? "col s12 l4 hide" : "col s12 l4 hide-on-med-and-down"
        }
      >
        {col1}
      </div>
      <div
        className={
          onlyCommonCol ? "col s12 l4 hide" : "col s12 l4 hide-on-med-and-down"
        }
      >
        {col2}
      </div>
      <div
        className={
          onlyCommonCol ? "col s12 l4 hide" : "col s12 l4 hide-on-med-and-down"
        }
      >
        {col3}
      </div>
      <div
        className={onlyCommonCol ? "col s12" : "col s12 hide-on-large-only"}
        style={onlyCommonCol ? { maxWidth: "450px", minWidth: "370px" } : {}}
      >
        {allUserCards}
      </div>
    </div>
  );
};

export default UserSmallCardList;
