import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import noAvatar from "../../../images/no-avatar.png";

const Message = ({
  isOwner,
  name,
  text,
  datetime,
  userAlias = null,
  isPackageMessage = 0,
  isModerator = null,
  isOrganizer = null,
  avatarUrl = null,
}) => {
  return (
    <div>
      {isOwner ? (
        <div className="row mt10 mb10">
          <div className="col s4"></div>
          <div className="col s8 card accent-color-bg primary-color">
            <div className="card-content" style={{ padding: "15px" }}>
              <div className="row nm">
                {/* <div className="col s3 l2">
                  <div
                    className="circular-image pastel-shadow"
                    style={{
                      width: "45px",
                      height: "45px",
                    }}
                  >
                    <div
                      className={
                        avatarUrl
                          ? "circular-image"
                          : "circular-image image-theme-filter"
                      }
                      style={{
                        backgroundImage:
                          "url(" + (avatarUrl ? avatarUrl : noAvatar) + ")",
                        height: "45px",
                        width: "45px",
                      }}
                    ></div>
                  </div>
                </div> */}
                <div className="col s12">
                  <div className="m700 mb10">
                    {userAlias ? (
                      <Link
                        className="primary-color"
                        to={"/users/" + userAlias}
                      >
                        {name +
                          (isOrganizer
                            ? " (организатор)"
                            : isModerator
                            ? " (модератор)"
                            : "")}
                      </Link>
                    ) : (
                      name +
                      (isOrganizer
                        ? " (организатор)"
                        : isModerator
                        ? " (модератор)"
                        : "")
                    )}
                  </div>
                  <div>{text}</div>
                  <div className="right s300 mt10">
                    {moment(datetime).format("DD.MM.YYYY HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mt10 mb10">
          <div className="col s8 card secondary-color">
            <div className="card-content" style={{ padding: "15px" }}>
              <div className="row nm">
                <div className="col s12">
                  <div className="m700 mb10">
                    {userAlias ? (
                      <Link
                        className="secondary-color"
                        to={"/users/" + userAlias}
                      >
                        {name +
                          (isOrganizer
                            ? " (организатор)"
                            : isModerator
                            ? " (модератор)"
                            : "")}
                      </Link>
                    ) : (
                      name +
                      (isOrganizer
                        ? " (организатор)"
                        : isModerator
                        ? " (модератор)"
                        : "")
                    )}
                  </div>
                  <div>{text}</div>
                  <div className="right s300 mt10">
                    {moment(datetime).format("DD.MM.YYYY HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col s4"></div>
        </div>
      )}
    </div>
  );
};

export default Message;
