import React from "react";

const InfoLabel = ({ icon }) => {
  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "-12px",
        bottom: "0px",
        backgroundColor: "var(--accent-color)",
        borderRadius: "100%",
        width: "30px",
        height: "30px",
      }}
    >
      <div
        className="material-icons primary-color"
        style={{
          fontSize: "20px",
          marginLeft: "5.5px",
          marginTop: "4px",
        }}
      >
        {icon}
      </div>
    </div>
  );
};

export default InfoLabel;
