import moment from "moment";
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import NoAvatar from "../images/no-avatar.png";
import { formatNumber } from "../utils/service";
import { Button, Icon } from "react-materialize";
import { AuthContext } from "../context";
import useConfirm from "../context/ConfirmDialog";

const Tickets = () => {
  const [ticketsData, setTicketsData] = useState(null);
  const [balanceData, setBalanceData] = useState(null);
  const [ticketsError, setTicketsError] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [paidBalances, setPaidBalances] = useState(null);
  const [notPaidBalances, setNotPaidBalances] = useState(null);
  const [attendance, setAttendance] = useState(0);
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();

  useEffect(() => {
    getTickets();
    getObjectBalance();
  }, []);

  const getTickets = async () => {
    const response = (await RequestService.getTickets(params.id)).data;
    // console.log(response);
    if (!response.response_code) {
      setTicketsError(response.message);
      return;
    }
    setTicketsData(response.result);
    createParticipants(response.result);
  };

  const getObjectBalance = async () => {
    const response = (await RequestService.getObjectBalance(params.id)).data;
    // console.log(response);
    if (!response.response_code) {
      setTicketsError(response.message);
      return;
    }
    setBalanceData(response.result);
    createBalance(response.result);
  };

  const createBalance = (balances) => {
    let paidList = [];
    let notPaidList = [];
    balances.paid.forEach((element) => {
      if (element.total > 0) {
        paidList.push(
          <div className="col s12 success-color" key={Math.random() * 10000}>
            {element.code}: {formatNumber(element.total)}{" "}
            {element.symbol ? element.symbol : ""}
          </div>
        );
      }
    });

    balances.not_paid.forEach((element) => {
      if (element.total > 0) {
        notPaidList.push(
          <div className="col s12 alert-color" key={Math.random() * 10000}>
            {element.code}: {formatNumber(element.total)}{" "}
            {element.symbol ? element.symbol : ""}
          </div>
        );
      }
    });

    setPaidBalances(paidList);
    setNotPaidBalances(notPaidList);
  };

  const dropOrder = async (ticketId) => {
    const choice = await confirm({
      description:
        "Вы действительно хотите отменить участие для вашего участника? Если он оплатил участие, то мы вернем ему деньги",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.dropTicket(ticketId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description:
          "Если участник вносил плату за участие, то оно будет аннулировано в течение суток. В течение этого времени участие продолжит отображаться в данном списке. Повторно подавать заявку на отмену участия НЕ НУЖНО.",
        isAlert: true,
      });

      getTickets();
    }
  };

  const rateParticipant = async (alias, rate, orderId) => {
    setFullScreenLoading(true);
    const response = (
      await RequestService.rateParticipant(alias, rate, orderId)
    ).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    getTickets();
  };

  const createParticipants = (participants) => {
    let list = [];

    let meetings = [];
    let expiredMeetings = [];
    participants.forEach((element) => {
      if (element.is_expired) {
        expiredMeetings.push(element.datetime);
      }
      meetings.push(element.datetime);
    });
    meetings = [...new Set(meetings)];
    expiredMeetings = [...new Set(expiredMeetings)];
    // let meetingsObj = {};
    // meetings.forEach(element => {
    //   meetingsObj[element] = [];
    // });
    meetings.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    expiredMeetings.sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime()
    );
    // console.log(meetings);
    // console.log(expiredMeetings);

    participants.sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    );
    // console.log(participants);

    let visitedCount = 0;
    let hasExpiredMeetings = 0;
    meetings.forEach((meeting) => {
      let meetingParticipants = 0;
      let meetingVisitedCount = 0;
      let maleCount = 0;
      let femaleCount = 0;
      let unknownSexCount = 0;
      if (!hasExpiredMeetings) {
        expiredMeetings.forEach((em) => {
          if (meeting == em) {
            list.push(
              <div
                className="col s12 mb15 l300 center-align"
                key={Math.random() * 100000}
              >
                <div className="divider mt15"></div>
                <div className="mt15 mb10">↓ Прошедшие встречи ↓</div>
              </div>
            );
            hasExpiredMeetings = 1;
          }
        });
      }

      let plist = [];
      let meetingName = "";

      participants.forEach((element) => {
        if (element.datetime == meeting) {
          meetingName = element.package;
          if (element.sex == 1) {
            maleCount++;
          } else if (element.sex == 2) {
            femaleCount++;
          } else {
            unknownSexCount++;
          }
          meetingParticipants++;
          if (element.completed_at) {
            visitedCount++;
            meetingVisitedCount++;
          }

          plist.push(
            <div className="col s12 mb25" key={Math.random() * 10000}>
              <div className="card bg animation-appear">
                <div className="card-content">
                  <div className="row nm valign-wrapper">
                    <div className="col s4 l2">
                      <div
                        className="circular-image pastel-shadow"
                        style={{
                          width: "70px",
                          height: "70px",
                          position: "relative",
                          margin: "auto",
                        }}
                      >
                        <div
                          className={
                            element.avatar_img_url
                              ? "circular-image hand-cursor"
                              : "circular-image image-theme-filter hand-cursor"
                          }
                          style={{
                            backgroundImage:
                              "url(" +
                              (element.avatar_img_url
                                ? element.avatar_img_url
                                : NoAvatar) +
                              ")",
                            height: "70px",
                            width: "70px",
                          }}
                          onClick={() => {
                            navigate("/users/" + element.alias);
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="col s8 l10">
                      <div className="row nm">
                        <div
                          className="col s12 l500 mb10 iq-link"
                          onClick={() => {
                            navigate("/users/" + element.alias);
                          }}
                        >
                          {element.first_name + " " + element.last_name}
                        </div>
                        <div className="col s12 mt5">
                          {moment(element.timestamp).format(
                            "DD.MM.YYYY HH:mm:ss"
                          )}
                        </div>
                        {element.location ? (
                          <div className="col s12 mt5">
                            Откуда: {element.location}
                          </div>
                        ) : null}
                        <div className="col s12 mt5">
                          Пакет: {element.package}
                        </div>
                        <div className="col s12 mt5">
                          Цена:{" "}
                          {formatNumber(element.price) +
                            " " +
                            element.currency_code}
                        </div>
                        {element.object_type != 3 ? (
                          <div
                            className={
                              element.status == 1
                                ? "col s12 mt5 success-color"
                                : "col s12 mt5 alert-color"
                            }
                          >
                            Внесена оплата: {element.status == 1 ? "Да" : "Нет"}
                          </div>
                        ) : null}

                        <div
                          className={
                            element.completed_at
                              ? "col s12 mt5 success-color"
                              : "col s12 mt5 alert-color"
                          }
                        >
                          Время посещения:{" "}
                          {element.completed_at
                            ? moment(element.completed_at).format(
                                "DD.MM.YYYY HH:mm:ss"
                              )
                            : "пока не пришел"}
                        </div>

                        {moment(element.datetime).isBefore() ? (
                          <div>
                            <div className="col s12 l500 mt15">
                              Оцените участника
                            </div>
                            <div className="col s12 mt5">
                              <i
                                className="material-icons alert hand-cursor"
                                style={{ fontSize: "42px", width: "42px" }}
                                onClick={() => {
                                  rateParticipant(element.alias, 1, element.id);
                                }}
                              >
                                {element.review_data.rate >= 1
                                  ? "star"
                                  : "star_outlined"}
                              </i>
                              <i
                                className="material-icons alert hand-cursor"
                                style={{ fontSize: "42px", width: "42px" }}
                                onClick={() => {
                                  rateParticipant(element.alias, 2, element.id);
                                }}
                              >
                                {element.review_data.rate >= 2
                                  ? "star"
                                  : "star_outlined"}
                              </i>
                              <i
                                className="material-icons alert hand-cursor"
                                style={{ fontSize: "42px", width: "42px" }}
                                onClick={() => {
                                  rateParticipant(element.alias, 3, element.id);
                                }}
                              >
                                {element.review_data.rate >= 3
                                  ? "star"
                                  : "star_outlined"}
                              </i>
                              <i
                                className="material-icons alert hand-cursor"
                                style={{ fontSize: "42px", width: "42px" }}
                                onClick={() => {
                                  rateParticipant(element.alias, 4, element.id);
                                }}
                              >
                                {element.review_data.rate >= 4
                                  ? "star"
                                  : "star_outlined"}
                              </i>
                              <i
                                className="material-icons alert hand-cursor"
                                style={{ fontSize: "42px", width: "42px" }}
                                onClick={() => {
                                  rateParticipant(element.alias, 5, element.id);
                                }}
                              >
                                {element.review_data.rate >= 5
                                  ? "star"
                                  : "star_outlined"}
                              </i>
                            </div>
                          </div>
                        ) : null}

                        <div className="col s12 mt15 right-align">
                          <Button
                            className="white-btn"
                            onClick={() => {
                              dropOrder(element.id);
                            }}
                          >
                            Отменить участие
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });

      let meetingHeader = (
        <div className="col s12 mb15 l500" key={Math.random() * 100000}>
          {!meeting
            ? "К вам желают присоединиться следующие пользователи"
            : "Встреча " +
              moment(meeting).format("DD.MM.YYYY HH:mm") +
              " «" +
              meetingName +
              "»" +
              " (посетили " +
              formatNumber(
                ((meetingVisitedCount / meetingParticipants) * 100).toFixed(1)
              ) +
              "%)" +
              " [среди всех участников (пришедших и непришедших) " +
              formatNumber(
                ((maleCount / meetingParticipants) * 100).toFixed(1)
              ) +
              "% мужчин, " +
              formatNumber(
                ((femaleCount / meetingParticipants) * 100).toFixed(1)
              ) +
              "% женщин, " +
              formatNumber(
                ((unknownSexCount / meetingParticipants) * 100).toFixed(1)
              ) +
              "% пол неизвестен]"}
        </div>
      );

      list.push(meetingHeader, ...plist);
    });

    setAttendance((visitedCount / participants.length) * 100);

    setParticipants(list);
  };

  return (
    <RegularPage
      error={ticketsError}
      isDataExist={ticketsData}
      header={"Заявки"}
      backText={"Назад"}
    >
      <div className="row mt25">
        <div className="col s12">
          {ticketsData &&
          ticketsData.length > 0 &&
          ticketsData[0].object_type != 2 ? (
            <div>
              Мы помогаем собирать денежные средства только в рамках
              мероприятий, поэтому вывод средств доступен только от продажи
              участия в событиях.
            </div>
          ) : (
            <div>
              Чтобы запросить вывод средств, напишите нам с email, к которому
              привязан ваш аккаунт, на{" "}
              <span className="accent-color">
                {window.nott.info.support_email}
              </span>
              . Тему письма укажите «Вывод средств». В письме укажите ФИО и ваши
              реквизиты (номер карты банка РФ / полные реквизиты банковского
              счета). Ваш запрос будет обработан в течение суток.
            </div>
          )}
        </div>
      </div>
      {ticketsData &&
      ticketsData.length > 0 &&
      ticketsData[0].object_type != 3 ? (
        <div className="row mt25">
          <div className="col s12 l6">
            <div className="row">
              <div className="col s12 l500 mb10">Получено оплат:</div>
              {!paidBalances || paidBalances.length == 0 ? (
                <div className="col s12 center-align">Оплат пока нет</div>
              ) : (
                paidBalances
              )}
            </div>
          </div>
          <div className="col s12 l6">
            <div className="row nm">
              <div className="col s12 l500 mb10">Не оплачено:</div>
              {!notPaidBalances || notPaidBalances.length == 0 ? (
                <div className="col s12 center-align">Оплат пока нет</div>
              ) : (
                notPaidBalances
              )}
            </div>
          </div>

          <div className="col s12 center-align xl500 mt25">
            Посещаемость: {formatNumber(attendance.toFixed(1))}%
          </div>
        </div>
      ) : null}

      <div className="row mt25">
        {!participants || participants.length == 0 ? (
          <div className="col s12 center-align">Участников пока нет</div>
        ) : (
          participants
        )}
      </div>
    </RegularPage>
  );
};

export default Tickets;
